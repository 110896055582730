<template>
  <div class="accessibility contentWrapper">
    <h1>Accessibility</h1>
    <div class="content">
      <p>NaturalPoint Inc. is committed to accessibility, inclusion, and to facilitating the accessibility and usability of our website for all people with disabilities. Working with our internal accessibility experts, we have and will continue to implement the relevant portions of the World Wide Web Consortium’s Web Content Accessibility Guidelines (WCAG) as our web accessibility standard. Our website will be tested periodically with assistive technology such as screen readers and screen magnifiers, and by users with disabilities who use these technologies. We have adopted a pro-active accessibility policy to support our commitment to the accessibility of the website.</p>
      <p>
        Please be aware that accessibility is an ongoing effort, and it is the goal to ensure that new content and features conform to WCAG. Specifically, if you are disabled and you encounter something on our website that is inaccessible, does not offer an equivalent experience, or encounter an accessibility issue, let us know. If possible, please specify the Web page in your email and the exact issues you are experiencing, and we will make all reasonable efforts to make that page accessible for you and/or to otherwise facilitate your experience in dealing with us. If, at any time, you have specific questions or concerns about the accessibility of any particular page on our website, send your questions and comments to us at <a href="mailto:marketing@optitrack.com">marketing@optitrack.com</a>. You can also call <a href="tel:15417536645">1-541-753-6645</a> to address website issues, to obtain information or complete transactions including purchases and returns.
      </p>
    </div>
  </div>
  <!-- END accessability -->
</template>
<script>
export default {
  name: "Accessibility"
};
</script>
<style lang="scss" scoped>
.accessibility {
  text-align: center;

  h1 {
    margin-bottom: 0px;
  }
  h1 ~ p {
    margin-top: 0px;
    margin-bottom: 45px;
  }

  h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 700;
  }

  h3 ~ p {
    margin-top: 0px;
  }

  .content {
    text-align: left;
    padding-bottom: 40px;
  }
}
</style>
